import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Warning as WarningIcon } from '@material-ui/icons';
import { Section, WarningMessage, NoticeHeader } from '../../util/styles';

export default function Overview(props) {
  return (
    <Section>
      <WarningMessage>
        <WarningIcon fontSize="small" color="secondary" />
        <NoticeHeader>Notice: We are not accepting new API partners at this time.</NoticeHeader>
        <ul>
          <li>
            We’ve paused approving new API partners onto Tekmetric at this time. Feel free to apply; however you will
            not hear back before April 2025 regarding the status of your application.
          </li>
          <li>
            Only shop owners requesting access to <u>their data</u> will be approved. API credentials provided to shop
            owners are strictly confidential and must not be shared with any third-parties under any circumstances.
            Unauthorized sharing or use of these credentials will be considered a breach of policy and may result in
            immediate revocation of access or other contractual consequences.
          </li>
        </ul>
      </WarningMessage>
      <h1>API Overview</h1>
      <Typography>
        The Tekmetric API allows shop owners to grant read only access to their Customer, Vehicle, and Repair Order data
        to 3rd party applications through a RESTful web service. Our API uses resource-oriented URLS, accepts query
        strings, returns JSON responses, and uses standard HTTP response codes, authentication, and verbs.
      </Typography>
    </Section>
  );
}
